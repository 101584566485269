import InputFile from 'components/InputFile';
import { Pergunta } from 'core/pesquisa/Pesquisa';
import CoreContext from 'providers/core';
import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';
import { storeMedia } from 'services/pesquisa';
import { PerguntaRef, genericRespostaAtual } from '../interfaces';
import { StyledLabel } from './styles';

interface Props {
  pergunta: Pergunta;
  className: string;
  respostaAtual: (id: number) => genericRespostaAtual;
}

interface ParamsProps {
  token: string;
}

const PerguntaFotoReferenciable: ForwardRefRenderFunction<
  PerguntaRef,
  Props
> = (props, ref) => {
  const { pergunta, className, respostaAtual } = props;
  const [preview, setPreview] = useState<File | null>(null);
  const [mediaLink, setMediaLink] = useState<string>('');
  const { handleSetLoading } = useContext(CoreContext);

  const { token } = useParams<ParamsProps>();

  const uploadMedia = useCallback(async file => {
    // colocando a aplicação em loading
    handleSetLoading(true);
    try {
      const response = await storeMedia(token, file);
      if (response.headers.location) {
        // parando o loading em casa de sucesso
        handleSetLoading(false);
      }
      setMediaLink(response.headers.location);
    } catch (err) {
      // parando o loading em casa de error
      handleSetLoading(false);
    }
  }, [token]);

  useImperativeHandle(ref, () => ({
    alternativas: null,
    data: null,
    texto: null,
    foto: mediaLink,
    fotoBlob: preview,
    destinoPulo: null,
    horario: null,
    alternativas_selecionadas: null,
    vazia: preview === null,
  }));

  useEffect(() => {
    if (preview !== null) {
      uploadMedia(preview);
    }
  }, [preview, uploadMedia]);

  // Consome o estado local armazenado na ultima ação de próximo/voltar
  // em uma pergunta
  useEffect(() => {
    const respostaLocalAtual = respostaAtual(pergunta.id);

    if (respostaLocalAtual && respostaLocalAtual.estadoAtual instanceof File) {
      setPreview(respostaLocalAtual.estadoAtual);
    }
  }, [pergunta.id, respostaAtual]);

  return (
    <div className={`${className}`}>
      {/* TODO - Habilitar h2 abaixo e criar funcionalidade de tirar uma foto no lugar de fazer upload de uma imagem */}
      {/* <h2 className="text-xs normal font-normal font-roboto text-fontTensai pt-2 pb-6">
        Você pode escolher entre tirar uma foto ou anexar uma foto já salva no
        seu computador
      </h2> */}
      <StyledLabel>Insira uma foto</StyledLabel>
      <div className="flex">
        <InputFile
          name="fileInput"
          preview={preview}
          setPreview={setPreview}
          accept="image/*"
        />
      </div>
    </div>
  );
};
const PerguntaFoto = forwardRef(PerguntaFotoReferenciable);
export { PerguntaFoto };
