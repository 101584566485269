import React from 'react';
import { ReactComponent as ClipImg } from '../../assets/images/clip.svg';

const Clip: React.FC<{ className: string }> = ({
  className,
}: {
  className: string;
}) => {
  return <ClipImg width="18px" height="28px" className={className} />;
};

export { Clip };
